import RasaLogo from "@images/rasa-logo.svg";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { getToken } from "../../../api/noAuthApi";
import { getPerson } from "../../../api/secureApi";
import { MANDATORY_SURVEY, OPTIONAL_SURVEY } from "../../../constants/surveyTypes";
import { loading } from "../../../components/loading";
import { handleError } from "../../../utils/handleError";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { DO_NOT_SHOW_MENU_STATUSES } from "./workflowStatuses";
import { errorUnderInput, inputLabel } from "../../../constants/classnames";
import { AZ } from "../../../constants/usStates";

export const showMenu = (client_status) => {
  // todo get more statuses for comparison here?
  return !DO_NOT_SHOW_MENU_STATUSES.includes(client_status);
};

export const determineRoute = (person, navigate, setIsLoading) => {
  if (person.verified && showMenu(person.client_status)) {
    setIsLoading(false);
    navigate("/menu", { state: person });
  } else if (person.verified) {
    setIsLoading(false);
    navigate("/results");
  } else if (person.app_payment_date) {
    setIsLoading(false);
    navigate("/register/verification", { state: person });
  } else {
    //    check optional survey; if exists, send them to optional
    //    check mandatory survey; if exists, send them to mandatory
    //   else send them to extended info page
    const currentMandatorySurvey = person.surveys.find(
      (survey) => survey.type === MANDATORY_SURVEY,
    );
    const currentOptionalSurvey = person.surveys.find((survey) => survey.type === OPTIONAL_SURVEY);
    if (currentOptionalSurvey) {
      if (currentOptionalSurvey.answers.interested_info?.length > 0) {
        setIsLoading(false);
        navigate("/register/payment", { state: person });
      } else if (currentOptionalSurvey.answers.clearing_reason) {
        setIsLoading(false);
        navigate("/register/interested-info", { state: person });
      } else if (currentOptionalSurvey.answers.denied_employment) {
        setIsLoading(false);
        navigate("/register/why-record-clearing", { state: person });
      } else if (currentOptionalSurvey.answers.denied_housing) {
        setIsLoading(false);
        navigate("/register/denied-employment", { state: person });
      } else if (currentOptionalSurvey.answers.health_insurance) {
        setIsLoading(false);
        navigate("/register/denied-housing", { state: person });
      } else if (currentOptionalSurvey.answers.education_level) {
        setIsLoading(false);
        navigate("/register/health-insurance", { state: person });
      } else if (currentOptionalSurvey.answers.job_opportunities) {
        setIsLoading(false);
        navigate("/register/education-level", { state: person });
      } else if (currentOptionalSurvey.answers.industry_want) {
        setIsLoading(false);
        navigate("/register/job-opportunities", { state: person });
      } else if (currentOptionalSurvey.answers.industry_current) {
        setIsLoading(false);
        navigate("/register/industry-want", { state: person });
      } else if (currentOptionalSurvey.answers.hourly_rate) {
        setIsLoading(false);
        navigate("/register/industry-current", { state: person });
      } else {
        navigate("/register/discount-questions", { state: person });
        setIsLoading(false);
      }
    } else if (currentMandatorySurvey) {
      // mandatory survey has been started
      // do more checking here to send them more accurately
      if (currentMandatorySurvey.answers.arizona_counties) {
        navigate("/register/court-data", { state: person });
      } else if (currentMandatorySurvey.answers.income_range) {
        if (person.us_state === AZ) {
          navigate("/register/arizona-data", { state: person });
        } else {
          navigate("/register/court-data", { state: person });
        }
      } else if (currentMandatorySurvey.answers.expunged_before) {
        navigate("/register/income", { state: person });
      } else if (currentMandatorySurvey.answers.other_convictions) {
        navigate("/register/expunged", { state: person });
      } else if (currentMandatorySurvey.answers.on_probation_or_parole) {
        navigate("/register/other-convictions", { state: person });
      } else if (currentMandatorySurvey.answers.open_cases) {
        navigate("/register/parole", { state: person });
      } else {
        navigate("/register/open-cases", { state: person });
      }
      setIsLoading(false);
    } else if (person.address) {
      setIsLoading(false);
      navigate("/register/ethnicity", { state: person });
    } else if (person.aliases.length > 0) {
      setIsLoading(false);
      navigate("/register/address", { state: person });
    } else if (person.phone) {
      setIsLoading(false);
      navigate("/register/aliases", { state: person });
    } else {
      setIsLoading(false);
      navigate("/register/extended-info", { state: person });
    }
  }
};

export function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    // control,
  } = useForm({ mode: "onBlur" });

  const onSubmit = (data) => {
    // todo if they haven't completed the questionnaire, send them to ?
    //  else, send them to results
    setIsLoading(true);
    const dataToSubmit = {
      username: data.username,
      password: data.password,
    };
    getToken(dataToSubmit)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("token response----", response);
        localStorage.setItem("token", response.access_token);

        getPerson()
          .then((person) => {
            // eslint-disable-next-line no-console
            console.log("responseId----", person);
            localStorage.setItem("profile_id", person.profile_id);
            if (person.title) {
              // we will use this to preselect in the pronoun page
              localStorage.setItem("title", person.title);
            }
            determineRoute(person, navigate, setIsLoading);
          })
          .catch((errorId) => {
            // eslint-disable-next-line no-console
            console.log("errorId----", errorId);
            handleError(errorId, setGenericErrorMessage, navigate);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("token error----", error);
        handleError(error, setGenericErrorMessage, navigate);
        setIsLoading(false);
      });
  };

  return (
    <>
      <nav className="container relative mx-auto p-6">
        <div className="flex justify-center">
          {/*logo*/}
          <RasaLogo className="h-9" />
        </div>
      </nav>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="mb-6 text-center font-serif text-3xl">login</h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto mb-16 mt-10 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <label htmlFor="username" className={inputLabel}>
                email *
              </label>
              <div className="relative rounded-md shadow-sm">
                <input
                  type="username"
                  name="username"
                  id="username"
                  className="block w-full rounded-md border-0 p-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  // placeholder="you@example.com"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="username-error"
                  {...register("username", { required: true, pattern: /^\S+@\S+\.\S+$/ })}
                />
              </div>
              {errors.username && (
                <div className={errorUnderInput} id="username-error">
                  Email address is required.
                </div>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="password" className={inputLabel}>
                password *
              </label>
              <div className="relative flex rounded-md shadow-sm">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  className="-mr-7 block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                  // placeholder="Create a password"
                  defaultValue=""
                  aria-invalid="false"
                  aria-describedby="password-error"
                  {...register("password", { required: true, minLength: 8 })}
                />
                {showPassword ? (
                  <EyeSlashIcon
                    className="relative -left-5 top-4 h-7 text-gray-400"
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <EyeIcon
                    className="relative -left-5 top-4 h-7 text-gray-400"
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
              {errors.password && (
                <div className={errorUnderInput} id="password-error">
                  Password is required.
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                {isLoading ? loading : "login"}
              </button>
              <Link onClick={() => navigate("/forgot")}>
                <div className="mb-6 mt-2 text-center text-lg text-rasapink underline">
                  forgot password?
                </div>
              </Link>
              <Link to="/register">
                <button
                  type="button"
                  className="w-full rounded-full bg-white px-24 py-4 font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-rasapink hover:shadow-xl"
                >
                  sign up
                </button>
              </Link>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
