import { createBrowserRouter } from "react-router-dom";
import { App } from "../App";
import { BasicLayout } from "@layouts";
import { TestPage } from "./test";
import { Verification } from "./register/verification";
import { Login } from "./login/login";
import { ForgotPassword } from "./login/ForgotPassword";
import { IntroInfo } from "./register/introInfo";
import { BasicInfo } from "./register/personInfo/basicInfo";
import { ExtendedInfo } from "./register/personInfo/extendedInfo";
import { Aliases } from "./register/personInfo/aliases/aliases";
import { Address } from "./register/personInfo/address";
import { Ethnicity } from "./register/personInfo/ethnicity";

import { Results } from "./results/results";
import { OpenCases } from "./register/surveyMandatory/openCases";
import { AccountCreated } from "./register/accountCreated";
import { Parole } from "./register/surveyMandatory/parole";
import { OtherConvictions } from "./register/surveyMandatory/otherConvictions/otherConvictions";
import { Expunged } from "./register/surveyMandatory/expunged/expunged";
import { Income } from "./register/surveyMandatory/income/income";
import { ArizonaData } from "./register/surveyMandatory/arizonaData";
import { CourtData } from "./register/courtData";
import { DiscountQuestions } from "./register/surveyOptional/discountQuestions";
import { Employed } from "./register/surveyOptional/employed";
import { PayRate } from "./register/surveyOptional/payRate";
import { IndustryCurrent } from "./register/surveyOptional/industryCurrent";
import { IndustryWant } from "./register/surveyOptional/industryWant";
import { EducationLevel } from "./register/surveyOptional/educationLevel";
import { HealthInsurance } from "./register/surveyOptional/healthInsurance";
import { DeniedHousing } from "./register/surveyOptional/deniedHousing";
import { DeniedEmployment } from "./register/surveyOptional/deniedEmployment";
import { WhyRecordClearing } from "./register/surveyOptional/whyRecordClearing";
import { InterestedInfo } from "./register/surveyOptional/interestedInfo";
import { RecordsReady } from "./register/recordsReady";
import { Payment } from "./register/payment/payment";
import { Suspense } from "react";
import { Menu } from "./menu";
import { PersonalInfoIntro } from "./personalStatementInfo/personalInfoIntro";
import { ProbationCompliance } from "./personalStatementInfo/probationCompliance";
import { Barriers } from "./personalStatementInfo/barriers";
import { ClearingReasons } from "./personalStatementInfo/clearingReasons";
import { Employment } from "./personalStatementInfo/employment";
import { Education } from "./personalStatementInfo/education";
import { VolunteerWork } from "./personalStatementInfo/volunteerWork";
import { Children } from "./personalStatementInfo/children";
import { Housing } from "./personalStatementInfo/housing";
import { LifeImprovement } from "./personalStatementInfo/lifeImprovement";
import { AdditionalInfo } from "./personalStatementInfo/additionalInfo";
import { LifeCircumstances } from "./personalStatementInfo/lifeCircumstances";
import { Final } from "./personalStatementInfo/final";
import { Overview } from "./personalStatementInfo/overview";
import { Grandchildren } from "./personalStatementInfo/grandchildren";
import { MenuLayout } from "../../layouts/menuLayout";
import { SurveyLayout } from "../../layouts/surveyLayout";
import { Pronouns } from "./personalStatementInfo/pronouns";
import { Intro } from "./register/intro";
import { ResetPassword } from "./login/ResetPassword";
import { JobOpportunities } from "./register/surveyOptional/jobOpportunities";
import { GovernmentBenefits } from "./register/surveyMandatory/governmentBenefits";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "*",
    element: <App />,
  },
  {
    path: "onboarding",
    element: <BasicLayout />,
    children: [
      {
        path: "basics",
        element: <Intro />,
      },
    ],
  },
  {
    path: "onboard",
    element: <BasicLayout />,
    children: [
      {
        path: "basics",
        element: <Intro />,
      },
    ],
  },
  {
    path: "r",
    element: <BasicLayout />,
    children: [
      { index: true, element: <Intro /> },
      {
        path: "*",
        element: <Intro />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<div className="m-12">loading...</div>}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/forgot",
    element: <ForgotPassword />,
  },
  {
    path: "/reset",
    element: <ResetPassword />,
  },
  {
    path: "/menu",
    element: <Menu />,
  },
  {
    path: "register",
    element: <BasicLayout />,
    children: [
      { index: true, element: <Intro /> },
      {
        path: "intro",
        element: <Intro />,
      },
      {
        path: "intro-info",
        element: <IntroInfo />,
      },
      {
        path: "basic-info",
        element: <BasicInfo />,
      },
      {
        path: "extended-info",
        element: <ExtendedInfo />,
      },
      {
        path: "aliases",
        element: <Aliases />,
      },
      {
        path: "address",
        element: <Address />,
      },
      {
        path: "ethnicity",
        element: <Ethnicity />,
      },
      {
        path: "created",
        element: <AccountCreated />,
      },
    ],
  },
  {
    path: "register",
    element: <SurveyLayout />,
    children: [
      {
        path: "open-cases",
        element: <OpenCases />,
      },
      {
        path: "parole",
        element: <Parole />,
      },
      {
        path: "other-convictions",
        element: <OtherConvictions />,
      },
      {
        path: "expunged",
        element: <Expunged />,
      },
      {
        path: "income",
        element: <Income />,
      },
      {
        path: "government-benefits",
        element: <GovernmentBenefits />,
      },
      {
        path: "court-data",
        element: <CourtData />,
      },
      {
        path: "arizona-data",
        element: <ArizonaData />,
      },
      {
        path: "discount-questions",
        element: <DiscountQuestions />,
      },
      {
        path: "employed",
        element: <Employed />,
      },
      {
        path: "pay-rate",
        element: <PayRate />,
      },
      {
        path: "industry-current",
        element: <IndustryCurrent />,
      },
      {
        path: "industry-want",
        element: <IndustryWant />,
      },
      {
        path: "job-opportunities",
        element: <JobOpportunities />,
      },
      {
        path: "education-level",
        element: <EducationLevel />,
      },
      {
        path: "health-insurance",
        element: <HealthInsurance />,
      },
      {
        path: "denied-housing",
        element: <DeniedHousing />,
      },
      {
        path: "denied-employment",
        element: <DeniedEmployment />,
      },
      {
        path: "why-record-clearing",
        element: <WhyRecordClearing />,
      },
      {
        path: "interested-info",
        element: <InterestedInfo />,
      },
      {
        path: "records-ready",
        element: <RecordsReady />,
      },
      {
        path: "payment",
        element: <Payment />,
      },
      {
        path: "verification",
        element: <Verification />,
      },
    ],
  },
  {
    path: "results",
    element: <Results />,
  },
  {
    path: "statement-overview",
    element: <MenuLayout />,
    children: [{ index: true, element: <Overview /> }],
  },
  {
    path: "statement",
    element: <BasicLayout />,
    children: [
      { index: true, element: <PersonalInfoIntro /> },
      {
        path: "intro",
        element: <PersonalInfoIntro />,
      },
      {
        path: "life-circumstances",
        element: <LifeCircumstances />,
      },
      {
        path: "probation",
        element: <ProbationCompliance />,
      },
      {
        path: "barriers",
        element: <Barriers />,
      },
      {
        path: "reasons",
        element: <ClearingReasons />,
      },
      {
        path: "pronouns",
        element: <Pronouns />,
      },
      {
        path: "employment",
        element: <Employment />,
      },
      {
        path: "education",
        element: <Education />,
      },
      {
        path: "volunteer-work",
        element: <VolunteerWork />,
      },
      {
        path: "children",
        element: <Children />,
      },
      {
        path: "grandchildren",
        element: <Grandchildren />,
      },
      {
        path: "housing",
        element: <Housing />,
      },
      {
        path: "life-improvement",
        element: <LifeImprovement />,
      },
      {
        path: "additional-info",
        element: <AdditionalInfo />,
      },
      {
        path: "final",
        element: <Final />,
      },
    ],
  },
  {
    path: "test",
    element: <TestPage />,
  },
]);
