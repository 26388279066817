import RasaLogo from "@images/rasa-logo.svg";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { createMessage } from "../../../api/secureApi";
import { useState } from "react";
import { loading } from "../../../components/loading";
// import { toast } from "react-toastify";

export function MessageUsModal({
  open,
  setOpen,
  profileId,
  setReceivedMessage,
  setReceivedAnyMessage,
}) {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (message !== "") {
      const dataToSubmit = {
        body: message,
        type: "message",
      };
      setIsLoading(true);
      createMessage(profileId, dataToSubmit)
        .then((response) => {
          setError("");
          // eslint-disable-next-line no-console
          console.log("response", response);
          setReceivedMessage(true);
          setReceivedAnyMessage(true);
          setOpen(false);
          setIsLoading(false);
          // toast.success("Your message has been sent to Rasa.");
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error", error);
          setReceivedMessage(false);
          setError(
            "There was an error sending your message." +
              " Please email us at tech-help@rasa-legal.com",
          );
          setIsLoading(false);
        });
    } else {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[75vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => handleClose()}
              />
            </div>
            <div className="flex justify-center">
              <RasaLogo className="h-9" />
            </div>
            <AlertDialog.Title className="mt-8 px-10 font-serif text-2xl">
              Send us a message
            </AlertDialog.Title>
            <AlertDialog.Description className="mb-8 px-6 text-xl">
              <section id="form">
                <form>
                  <div className="container mx-auto mb-16 mt-10 flex max-w-3xl flex-col px-4">
                    <div className="pb-4">
                      <label htmlFor="message" className="block font-bold leading-6 text-gray-900">
                        Your message
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <textarea
                          type="message"
                          name="message"
                          id="message"
                          className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                  placeholder:text-gray-400 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-rasapink sm:text-sm sm:leading-6"
                          rows="8"
                          onChange={handleMessageChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center">
                      {error && (
                        <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                          {error}
                        </div>
                      )}
                      <button
                        onClick={handleSendMessage}
                        type="button"
                        className="my-6 w-full rounded-full bg-rasapink p-4 font-semibold text-white hover:shadow-xl"
                      >
                        {isLoading ? loading : "send message"}
                      </button>
                    </div>
                  </div>
                </form>
              </section>
            </AlertDialog.Description>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
